.login-page-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
}
.login-form {
  width: 100% !important;
}
.login-page-icon-container {
  display: flex;
  justify-content: center;
  padding: 1em;
}
.login-page-icon {
  width: 60%;
  height: 60%;
  user-select: none;
}
.login-form-button {
  left: 25%;
}
.rights {
  text-align: center;
}
