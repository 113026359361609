.container {
  display: flex;
  flex-direction: row;
}

.window {
  flex: 1;
}

.mainChartsPositions {
  margin-top: 2em;
}

.title {
  font-size: 18px;
}

.compareContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.left,
.right {
  display: inline-block;
  width: 50%;
  /* height: 100vh; Set the height of each div to the height of the viewport */
}

.clear {
  width: 100%;
}

li {
  margin-bottom: 5px;
}

.separate {
  margin-bottom: 15px;
}
